require("./scss/home.scss");
require("slick-carousel/slick/slick.css");
require("slick-carousel/slick/slick-theme.css");
require("slick-carousel");

// Slider principal single
$('.slider').slick({
    fade: false,
    // cssEase: 'linear',
    centerMode: false,
    autoplay: false,
    autoplaySpeed: 3000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    prevArrow:"<img class='a-left control-c prev slick-prev' src='dist/images/home/seta-esq.png'>",
    nextArrow:"<img class='a-right control-c next slick-next' src='dist/images/home/seta-dir.png'>"
  });

  // slick produtos

  $(document).ready(function(){
    $('.my-slider').slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      speed: 500,
      infinite: true,
      autoplaySpeed: 3000,
      autoplay: true,
      prevArrow:"<img class='a-left control-c prev slick-prev' src='dist/images/home/seta-esq.png'>",
      nextArrow:"<img class='a-right control-c next slick-next' src='dist/images/home/seta-dir.png'>",
      responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
    });
  });


  jQuery(function($) {
    var $s = $('.my-slider-two').slick({
          cssEase: 'linear',
          speed: 8000,
          autoPlay: false,
          pauseOnHover: false,
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              }
            } ]
      });
      $('.gotoright').click(function($) {
        $s.slick("setOption","speed",1,false)
      });
    $('.gotoleft').click(function($) {
      });
    });
